@import '../_vars';
.pot{
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0.2rem;
  &.MuiPaper-rounded{
    border-radius: 20px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  width: 4rem;
  height: 4rem;
  border: 2px solid grey;
  border-top: 11px solid grey;
  cursor: pointer;
  box-sizing: border-box;

  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-100%);
    color: white;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: bold;
  }

  .MuiSvgIcon-root{
    vertical-align: middle;
  }

  
  .pot-contents{
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pot-footer{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,2px);
    width: 100%;
  }

  
  
  .name{
    line-height: 1;
    font-size: 0.6rem;
    font-weight: bold;
    margin: 0.2rem 0;
    .variant + .ssa{
      margin-left: 2px;
    }
  }
  .date{
    line-height: 1;
    font-size: 0.7rem;
    padding: 0.2rem 0;
    small{
      display: block;
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }
  .freeze{
    display: inline;
    color: lightblue;
    line-height: 1;
    font-size: 0.8rem;
    .MuiSvgIcon-root{
      font-size: 0.8rem;
      line-height: 1;
    }
  }
  .notes{
    display: inline;
    color: lightgrey;
    line-height: 1;
    font-size: 0.8rem;
    .MuiSvgIcon-root{
      font-size: 0.8rem;
      line-height: 1;
    }
  }




  // declinaisons
  &.color-confiture{
    border-color: red;
    &:before{
      content: 'confiture';
    }
    .name{
      color: red;
    }
  }
  &.color-compote{
    border-color: green;
    &:before{
      content: 'compote';
    }
    .name{
      color: green;
    }
  }
  &.color-sauce{
    border-color: orange;
    &:before{
      content: 'sauce';
    }
    .name{
      color: orange;
    }
  }
  &.color-legumes{
    border-color: purple;
    &:before{
      content: 'legumes';
    }
    .name{
      color: purple;
    }
  }
  &.color-petitlait{
    border-color: lightgrey;
    &:before{
      content: 'p\'lait';
    }
    .name{
      color: lightgrey;
    }
  }
  &.color-plat{
    border-color: sienna;
    &:before{
      content: 'plat';
    }
    .name{
      color: sienna;
    }
  }
}