.toggleSideBtn{
  position: fixed !important;
  top: 40vh;
  right: 0;
  transition: 0.3s;
  height: 200px !important;
  border-radius: 4px !important;
  .MuiFab-label{
    color: #fff;
  }
  .toggled &{
    right: inherit;
    left: 0;
    width: 20px;
  }
}