@import '../_vars';
.ssa{
  font-size: 0.7rem;
  font-weight: bold;
}
.table-bains{
  .variant + .ssa{
    margin-left: 5px;
  }
  .countRestants{
    font-size: 1.1rem;
  }
  .archived{
    opacity: 0.6;
  }
}
.table-bains + .table-bains{
  margin-top: 1rem;
}