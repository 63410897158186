@import './_vars';

.MuiGrid-spacing-xs-2 {
  margin: 0 auto;
}

.wrapper{
  position: relative;
  &.MuiContainer-root{
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    overflow-x: hidden;
    height: 100%;
  }
  > .dashboard,
  > .side,
  > .version{
    transition: 0.3s;
    transform: translateX(0%);
  }
  > .dashboard{
    max-height: initial;
    overflow-y: auto;
    min-height: 100%;
  }
  > .side{
    max-height: 100%;
    overflow-y: hidden;

    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
  }
  > .version{
    margin-bottom: 1rem;
  }
  &.toggled{
    > .dashboard,
    > .side,
    > .version{
      transform: translateX(-100%);
    }
    > .dashboard{
      max-height: 100%;
      overflow-y: hidden;
    }
    > .side{
      max-height: initial;
      overflow-y: auto;
      min-height: 100%;
    }
  }
}



.modal-contents{
  background: #ffffff;
  width: 80%;
  max-height: 80%;
  max-width: 800px;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow-y: auto;
  &:focus{
    outline: 0;
  }
  h2{
    text-align: center;
  }
  .MuiFormControl-root{
    width: 100%;
    margin: 1rem auto;
    margin-bottom: 0;
  }
  .MuiGrid-grid-xs-12{
    .MuiInputLabel-outlined{
      width: calc( 50% - 28px );
    }
  }
  form{
    min-height: calc( 100% - 30px );
    // height: 100%;
    // padding-bottom: 2rem;
    box-sizing: border-box;
  }
  .modal-actions{
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,0.8);
    transform: translateY(10px);
  }
}