@import '../_vars';
.edition{
  display: block;
  margin-bottom: 2rem;
  .id{
    font-size: 0.8rem;
    opacity: 0.6;
    font-style: italic;
  }
  .selectLabel{
    background: white;
    padding-right: 3px;
  }
  .MuiInputLabel-outlined{
    background: white;
    padding-bottom: 2px;
    width: calc( 100% - 28px );
    transform: translate(14px, 17px) scale(1);
    height: 20px;
    line-height: 20px;
  }

  .bain{
    margin-bottom: 2rem;
    &:after{
      content: '';
      display: block;
      clear: both;
    }
    .name{
      font-style: italic;
    }
    .category{
    }
    .type{
      font-weight: bold;
      text-transform: uppercase;
    }
    .variant{
      height: 1rem;
      line-height: 0.9rem;
    }
    .type + .variant{
      margin-left: 1rem;
      height: 1rem;
      line-height: 0.9rem;
    }
    .ssa{
      background: grey;
      color: white;
      border-radius: 4px;
      padding: 0px 10px;
      height: 1rem;
      line-height: 0.9rem;
    }
    .type + .ssa,
    .variant + .ssa{
      margin-left: 1rem;
    }
    .date{
      float: right;
      text-align: center;
      padding: 0.4rem;
      margin: 0.2rem;
      border: 1px dashed grey;
      border-radius: 10px;
      small{
        display: block;
        text-transform: uppercase;
        font-size: 0.6em;
        line-height: 0.6em;
        &:after{
          content: '';
          display: block;
          margin: 0 auto;
          height: 0.4rem;
          border-bottom: 1px solid grey;
          width: 20px;
          margin-bottom: 2px;
        }
      }
      span{
        display: block;
        font-size: 1em;
        line-height: 1em;
        &.year{
          font-weight: bold;
        }
      }
    }
    .quantity{
      margin-bottom: 1rem;
    }
    .quantity,
    .notes,
    .comments{
      font-size: 0.7rem;
      line-height: 1.2;
    }
    .comments{
      // font-size: 1rem;
      // line-height: 1rem;
      .MuiSvgIcon-root{
        font-size: 1rem;
        line-height: 1rem;
      }
      .appreciation,
      .notes{
        display: inline-block;
        vertical-align: middle;
      }
      .notes{
        transform: translateY(3px);
      }
      .appreciation + .notes{
        margin-left: 1rem;                                              
      }
    }

    // declinaisons
    &.color-confiture{
      color: red;
      .ssa{
        background-color: red;
      }
      .date{
        border-color: red;
        small:after{
          border-color: red;
        }
      }
    }
    &.color-compote{
      color: green;
      .ssa{
        background-color: green;
      }
      .date{
        border-color: green;
        small:after{
          border-color: green;
        }
      }
    }
    &.color-sauce{
      color: orange;
      .ssa{
        background-color: orange;
      }
      .date{
        border-color: orange;
        small:after{
          border-color: orange;
        }
      }
    }
    &.color-legumes{
      color: purple;
      .ssa{
        background-color: purple;
      }
      .date{
        border-color: purple;
        small:after{
          border-color: purple;
        }
      }
    }
    &.color-petitlait{
      color: lightgrey;
      .ssa{
        background-color: lightgrey;
      }
      .date{
        border-color: lightgrey;
        small:after{
          border-color: lightgrey;
        }
      }
    }
    &.color-plat{
      color: sienna;
      .ssa{
        background-color: sienna;
      }
      .date{
        border-color: sienna;
        small:after{
          border-color: sienna;
        }
      }
    }
  }

  .not-pertinent{
    opacity: 0.5;
  }
}