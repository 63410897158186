@import '../_vars';
.side{
  text-align: center;
  background-color: $color_background;
  font-size: calc(10px + 2vmin);
  color: $color_text;
  padding: 16px;
  flex-direction: column;
  &.MuiGrid-spacing-xs-2 {
    margin: 0;
    margin-top: -8px;
    margin-bottom: 1rem;
    padding-right: 0;
    padding-left: 0;
  }

  &.MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding-left: 16px;
    padding-right: 16px;
    &.MuiGrid-grid-xs-6:nth-child(2){
      padding-right: 8px;
    }
    &.MuiGrid-grid-xs-6:nth-child(3){
      padding-left: 8px;
    }
  }

  > .MuiGrid-item:nth-child(2){
    flex: 1;
  }

  .wrapper{
    overflow: hidden;
  }

  .MuiFormControl-root{
    width: 100%;
  }

  .more-notes-btn{
    float: right;
  }
  .paper-note{
    position: relative;
  }
  .remove-notes-btn{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
