@import '../_vars';
.configuration{
  p{
    font-size: 1rem;
  }


  .type-title{
    background: grey;
    padding: 0.2rem 1rem;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;

    // declinaisons
    &.color-confiture{
      background-color: red;
    }
    &.color-compote{
      background-color: green;
    }
    &.color-sauce{
      background-color: orange;
    }
    &.color-legumes{
      background-color: purple;
    }
    &.color-petitlait{
      background-color: lightgrey;
    }
    &.color-plat{
      background-color: sienna;
    }
  }

  .title2{
    background: grey;
    padding: 0.2rem 1rem;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
  }
}

