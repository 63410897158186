@import '../_vars';
.dashboard{
  &.MuiGrid-spacing-xs-2 {
    width: calc(100% + 24px);
    margin: -16px;
    margin-top: -8px;
    margin-bottom: 1rem;
  }

  text-align: center;
  background-color: $color_background;
  font-size: calc(10px + 2vmin);
  color: $color_text;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.2);

  &.MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding-left: 16px;
    padding-right: 16px;
    &.MuiGrid-grid-xs-6:nth-child(2){
      padding-right: 8px;
    }
    &.MuiGrid-grid-xs-6:nth-child(3){
      padding-left: 8px;
    }
    &.cell-smaller{
      padding: 0 3rem;
    }
  }

  .wrapper{
    overflow: hidden;
  }

  .cell{
    height: 100%;
  }

  .fullDimension{
    min-height: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .MuiPaper-root{
    position: relative;
    > .MuiTypography-root{
      opacity: 0.7;
      margin-bottom: 0.5rem;
    }
    .addlegend{
      margin: 0.5rem auto;
      font-size: 2rem;
      display: block;
      cursor: pointer;
      .MuiSvgIcon-root{
        font-size: 1.8em;
        color: $color_secondary;
        opacity: 0.4;
        transition: 0.3s;
      }
      &:hover{
        .MuiSvgIcon-root{
          opacity: 0.6;
        }
      }
      &.centered{
        margin-top: 4rem;
        @media screen and (max-width: 560px){
          margin-top: 2rem;
        }
      }
      &.dragging .MuiSvgIcon-root{
        opacity: 1;
        color: $color_text;
      }
    }
  }

  
  .flexer{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    .MuiCard-root{
      flex: 1;
      margin: 0.5rem;
      min-width: 150px;
      max-width: 250px;
    }
  }
}




.dashboard-small{
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: $color_text;

  .dashboard-small-cell{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > .MuiPaper-root{
      flex: 1;
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
    }
    .flexer{
      flex: 1;
    }
  }

  .title{
    opacity: 0.7;
    margin-bottom: 0.5rem;
  }
  .MuiPaper-root{
    position: relative;

    .addlegend{
      margin: 0.5rem auto;
      font-size: 2rem;
      display: block;
      cursor: pointer;
      outline: 0;
      .MuiSvgIcon-root{
        font-size: 1.8em;
        color: $color_secondary;
        opacity: 0.4;
        transition: 0.3s;
      }
      &:hover{
        .MuiSvgIcon-root{
          opacity: 0.6;
        }
      }
      &.centered{
        margin-top: 4rem;
        @media screen and (max-width: 560px){
          margin-top: 2rem;
        }
      }
      &.dragging .MuiSvgIcon-root{
        opacity: 1;
        color: $color_text;
      }
    }
  }

  
  .flexer{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    .MuiCard-root{
      flex: 1;
      margin: 0.5rem;
      min-width: 150px;
      max-width: 250px;
    }
  }
}