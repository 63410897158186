@import '../_vars';
.bains{
  .date{
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    .day{
      font-size: 0.7rem;
      line-height: 1;
    }
  }
  .names{
    line-height: 1;
    .variant{
      font-size: 0.9em;
      line-height: 0.9;
    }
  }
  .notes{
    opacity: 0.6;
    line-height: 1;
    .MuiSvgIcon-root{
      font-size: 0.9em; 
    }
  }
  .appreciation{
    line-height: 1;
    .MuiSvgIcon-root{
      font-size: 0.7em; 
    }
  }
  .statut{
    font-weight: bold;
  }
  .available{
    
  }
  .archived{
    background: rgba(0,0,0,0.05);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.05) inset;
  }

  .MuiTableCell-sizeSmall{
    padding: 3px 8px 3px 8px;
  }
}
    


.table-places{
  vertical-align: top;
}
.table-places + .table-places{
  margin-top: 1rem;
}