@import '../_vars';
.stats-types{

  .type-title{
    background: grey;
    padding: 0.2rem 1rem;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .type-restant{
    font-size: 2rem;
    font-weight: bold;
  }

  &.as-grid{
    .type-title{
      background: grey;
      padding: 0.2rem 1rem;
      color: white;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: bold;
    }
    .type-restant{
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &.as-rows{
    display: inline-block;
    text-align: left;
    line-height: 1rem;
    white-space: nowrap;
    .type-restant{
      display: inline-block;
      vertical-align: middle;
      font-size: 0.8rem;
      min-width: 14px;
      text-align: right;
    }
    .type-title{
      display: inline-block;
      vertical-align: middle;
      padding: 0rem 0.5rem;
      font-size: 0.6rem;
      margin-left: 0.3rem;
    }
  }


  .type-title{
    // declinaisons
    &.color-confiture{
      background-color: red;
    }
    &.color-compote{
      background-color: green;
    }
    &.color-sauce{
      background-color: orange;
    }
    &.color-legumes{
      background-color: purple;
    }
    &.color-petitlait{
      background-color: lightgrey;
    }
    &.color-plat{
      background-color: sienna;
    }
  }
  .type-restant{
    // declinaisons
    &.color-confiture{
      color: red;
    }
    &.color-compote{
      color: green;
    }
    &.color-sauce{
      color: orange;
    }
    &.color-legumes{
      color: purple;
    }
    &.color-petitlait{
      color: grey;
    }
    &.color-plat{
      color: sienna;
    }
  }
}